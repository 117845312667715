import React from 'react';
import Canasta from "./canasta/Canasta"
import QuienesSomos from "./QuienesSomos"
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import Footer from './Footer';
import styled from 'styled-components';
import InfoSemino from './InfoSemino';
import { Noticias } from './noticias/Noticias';
import Teatro from './Teatro';
import PortadaHome from './PortadaHome';
import PortadaVideo from './PortadaVideo';
import NotaEspecial from './noticias/NotaEspecial';
import ScrollConsultorio from './ScrollConsultorio';
import ScrollCapacitacion from './capacitacion/scrollCapacitacion';
import encuentro from "../../imagenes/encuentros2.jpg"
import encuentroCel from "../../imagenes/encuentros-cel.jpg"
import imprevistos from '../../imagenes/imprevistos.png'
import conversatorio from '../../imagenes/conversatorio.png'

import './fonts.css'


export default function Home() {

    ////Scrolea a la sección correspondiente cuando se clickea en la nav dedse otra ruta /////  
    const id = useParams()
    const [mostrarPantalla, setMostrarPantalla] = useState(true)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    const scrollToSection = (x) => {
        const sectionId = Object.values(x)
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView()
        }
    };

    useEffect(() => {
        const handleResize = () => { setScreenWidth(window.innerWidth) }
        window.addEventListener('resize', handleResize);

    }, [])

    useEffect(() => {
        scrollToSection(id);
        const time = setTimeout(() => { setMostrarPantalla(false) }, 700)
        return () => clearTimeout(time)
    }, [id]);
    ///////////////////////////////////////////////////////////

    return (
        <>
            <ContainerHome style={{ opacity: mostrarPantalla ? '0' : '1' }}>
                <PortadaHome />
                <PortadaVideo
                    video={screenWidth > 440 ? encuentro : encuentroCel}
                    link={"/ver-video/1"}
                />
                <hr />
                <br />
                <h3 className='titulo'>Noticias</h3>
                <Noticias />
                <hr />
                <br />
                <h3 className='titulo'>Contenidos especiales</h3>
                <br />
                <NotaEspecial
                    titulo="El colapso del sistema previsional"
                    texto="Video del conversatorio"
                    autor="Con Silvia Stang, Ismael Bermúdez y Eugenio Semino"
                    imagen={conversatorio}
                    link={"/ver-video/2"}
                />
                <br />
                <NotaEspecial
                    titulo="Cuarenta años de imprevistos"
                    texto="Tres artículos sobre la historia del sistema previsional en democracia"
                    autor="- Dr. Eugenio Semino -"
                    link="/notas-semino"
                    imagen={imprevistos}
                />
                <br /> <br />
                <ScrollConsultorio />
                <ScrollCapacitacion />
                <br />
                <Canasta />
                <Teatro />
                <QuienesSomos />
                <InfoSemino />
            </ContainerHome>
            <Footer />
        </>
    )
}


const ContainerHome = styled.div`

transition: opacity 0.4s;
margin: 0 auto;

@media (min-width: 768px) {
    width:80%;
}

.contenedor-imagen{
    width: 60%;
}

`

